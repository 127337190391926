import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Login from './Login';
import Types from './Types/index';
import Orders from './Orders';
import Expensess from './Expensess';
import { DataProvider } from '../contexts/DataContexst';
import Bar from './Bar';
import Reports from './Reports';

const App = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  return (
    <div>
      <Bar />
      <DataProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/types" element={<Types />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/expensess" element={<Expensess />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/" />
        </Routes>
      </DataProvider>
    </div>
  );
};

export default App;
