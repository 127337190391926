import {
  Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { doc, getFirestore, setDoc } from '@firebase/firestore';
import { productType } from '../../types';
import app from '../../firebase';

const EditType = ({ type }:{type:productType}) => {
  const [open, setOpen] = useState(false);
  const [Name, setName] = useState(type.Name);
  const [Price, setPrice] = useState(type.Price);
  const [Loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setName(type.Name);
    setPrice(type.Price);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton size="large" onClick={handleClickOpen}>
        <EditIcon fontSize="large" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>تعديل النوع</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            disabled={Loading}
            margin="dense"
            id="name"
            label="ألاسم"
            type="text"
            fullWidth
            variant="standard"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            autoFocus
            disabled={Loading}
            margin="dense"
            id="price"
            label="السعر"
            type="number"
            fullWidth
            variant="standard"
            value={parseInt(`${Price}`).toString()}
            onChange={(e) => setPrice(e.target.value ? parseInt(e.target.value) : 0)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={Loading}
            onClick={handleClose}
          >
            الغاء
          </Button>
          <Button
            disabled={Loading}
            onClick={async () => {
              setLoading(true);

              await setDoc(doc(getFirestore(app), 'types/', type.id), { ...type, Name, Price });

              setLoading(false);
              handleClose();
            }}
          >
            تعديل
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditType;
