import {
  IconButton, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Check, Close } from '@mui/icons-material';
import {
  Card, Col, Collapse, Container, Form, Row, Table,
} from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NewOrder from './NewOrder';
import { OrderType } from '../../types';
import { useData } from '../../contexts/DataContexst';
import EditOrder from './EditOrder';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import app from '../../firebase';

const getProdString = (data : OrderType) => {
  const string:string[] = [];
  data.Products.forEach((value) => {
    string.push(`${value.count}-${value.product.Name}`);
  });
  return string;
};

const dateToString = (date:Date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
type FiltersType = {
  Payment: 'all' | 'paid' | 'not paid';
  Delivery: 'all' | 'delivered' | 'not delivered';
}

const Index = () => {
  const { Orders } = useData();
  const [FilterdOrders, setFilterdOrders] = useState(Orders);
  const [Filters, setFilters] = useState<FiltersType>({
    Payment: 'all',
    Delivery: 'all',
  });
  const [Search, setSearch] = useState('');
  const [Expand, setExpand] = useState(false);


  useEffect(() => {
    const newOrders = Orders.filter((order) => {
      if (Filters.Payment === 'all') {
        return true;
      }
      if (Filters.Payment === 'paid') {
        return order.payment;
      }
      if (Filters.Payment === 'not paid') {
        return !order.payment;
      }
      return true;
    }).filter((order) => {
      if (Filters.Delivery === 'all') {
        return true;
      }
      if (Filters.Delivery === 'delivered') {
        return order.delivry;
      }
      if (Filters.Delivery === 'not delivered') {
        return !order.delivry;
      }
      return true;
    }).filter((order) => {
      if (Search === '') {
        return true;
      }
      const search = Search.toLowerCase();
      const orderstring = JSON.stringify(Object.values(order)).toLowerCase();
      return orderstring.includes(search);
    });
    setFilterdOrders(newOrders);
  }, [Filters, Orders, Search]);

  return (
    <div>
      <Collapse in={Expand}>
        <Card>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col>
                  <Form.Check
                    type="radio"
                    onChange={() => { setFilters({ ...Filters, Payment: 'paid' }); }}
                    label="مسدد"
                    name="payment"
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    onChange={() => { setFilters({ ...Filters, Payment: 'not paid' }); }}
                    label="لم يسدد"
                    name="payment"
                  />
                </Col>
                <Col>
                  <Form.Check
                    defaultChecked
                    type="radio"
                    onChange={() => { setFilters({ ...Filters, Payment: 'all' }); }}
                    label="الكل"
                    name="payment"
                  />
                </Col>
                <Col>
                  <Form.Label>
                    <p>
                      السداد
                    </p>
                  </Form.Label>
                </Col>

              </Row>

              <Row>
                <Col>
                  <Form.Check
                    type="radio"
                    onChange={() => { setFilters({ ...Filters, Delivery: 'delivered' }); }}
                    label="موصل"
                    name="Delivry"
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    onChange={() => { setFilters({ ...Filters, Delivery: 'not delivered' }); }}
                    label="لم يوصل"
                    name="Delivry"
                  />
                </Col>
                <Col>
                  <Form.Check
                    defaultChecked
                    type="radio"
                    onChange={() => { setFilters({ ...Filters, Delivery: 'all' }); }}
                    label="الكل"
                    name="Delivry"
                  />
                </Col>
                <Col>
                  <Form.Label>
                    <p>
                      التوصيل
                    </p>
                  </Form.Label>
                </Col>

              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="بحث"
                    onChange={(e) => { setSearch(e.target.value); }}
                  />
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Collapse>
      <div style={{ textAlign: 'center' }}>
        <IconButton onClick={() => setExpand((v) => !v)} size="small">
          {Expand ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
        </IconButton>
      </div>
      <NewOrder />
        <Table aria-label="simple table" responsive bordered>
          <thead>
            <tr>
              <th className="text-center">الاسم</th>
              <th className="text-center">الطلبات</th>
              <th className="text-center">المجموع</th>
              <th className="text-center">التاريخ</th>
              <th className="text-center">المكان</th>
              <th className="text-center">الملاحظات</th>
              <th className="text-center">التوصيل</th>
              <th className="text-center">السداد</th>
              <th className="text-center">تعديل</th>
              <th className="text-center">حذف</th>
            </tr>
          </thead>
          <tbody>
            {FilterdOrders.sort((a,b)=> b.CreatedAt.toDate().getTime() - a.CreatedAt.toDate().getTime()).map((row) => {
              let date:any = row.date;
              if (!(date instanceof Date)){
                date = date.toDate()
              }
              
              
              return(
              <tr
                key={row.id}
              >
                <td className="text-center"><Typography>{row.CustomerName}</Typography></td>
                <td className="text-center">{getProdString(row).map((v)=><p key={v} style={{fontSize:'12px'}}>{v}</p>)}</td>
                <td className="text-center">{row.total}</td>
                <td className="text-center">{dateToString(date)}</td>
                <td className="text-center">{row.place}</td>
                <td className="text-center">{row.notes}</td>
                <td className="text-center">{row.delivry ? <Check /> : <Close />}</td>
                <td className="text-center">{row.payment ? <Check /> : <Close />}</td>
                <td className="text-center">
                  <IconButton size="large">
                    <EditOrder Order={row} />
                  </IconButton>
                </td>
                <td className="text-center">
                  <IconButton size="large" onClick={()=>{
                    const db = getFirestore(app);
                    if(window.confirm('هل تريد حذف الطلب ؟')){
                      const ref = doc(db,'orders',row.id);
                      deleteDoc(ref).then(()=>{
                        alert('تم حذف الطلب بنجاح')
                      }).catch(()=>{
                        alert('حدث خطأ أثناء حذف الطلب')
                      })
                    }
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Index;
