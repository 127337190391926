import {
  Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import app from '../../firebase';

const NewType = () => {
  const [open, setOpen] = useState(false);
  const [Name, setName] = useState('');
  const [Price, setPrice] = useState(0);
  const [Loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <IconButton onClick={handleClickOpen} size="large">
        <AddIcon fontSize="large" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>نوع جديد</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            disabled={Loading}
            margin="dense"
            id="name"
            label="ألاسم"
            type="text"
            fullWidth
            variant="standard"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            disabled={Loading}
            id="Price"
            label="السعر"
            variant="standard"
            name="Price"
            value={Price}
            onChange={(e) => setPrice(parseInt(e.target.value))}
            onFocus={(e) => {
              e.target.select();
            }}
            type="number"
          />

        </DialogContent>
        <DialogActions>
          <Button
            disabled={Loading}
            onClick={handleClose}
          >
            الغاء
          </Button>
          <Button
            disabled={Loading}
            onClick={async () => {
              if (!Name || Price <= 0) return;
              setLoading(true);
              const id = uuidv4();
              const db = getFirestore(app);

              await setDoc(doc(db, 'types', id), { Name, Price, id });

              setLoading(false);
              handleClose();
              setName('');
              setPrice(0);
            }}
          >
            إضافة
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewType;
