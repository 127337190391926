import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar, Button, Link, Toolbar,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

export default function Bar() {
  const { logout, currentUser } = useAuth();
  const history = useNavigate();

  async function handleLogout() {
    await logout();
    history('/login');
  }

  return (
    <AppBar
      position="static"
      style={{
        marginBottom: 20,
        backgroundColor: 'transparent',
        boxShadow: 'none',

      }}
    >
      <Toolbar
        variant="dense"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-around',
        }}

      >
        <Link href="/types" underline="none" color="black" fontSize={24}>ألانواع</Link>

        <Link href="/orders" underline="none" color="black" fontSize={24}>الطلبات</Link>

        <Link href="/expensess" underline="none" color="black" fontSize={24}>المصاريف</Link>

        <Link href="/reports" underline="none" color="black" fontSize={24}>التقارير</Link>

        {
          currentUser
            ? (
              <Button onClick={handleLogout} variant="contained">
                تسجيل الخروج
              </Button>
            )
            : null
        }
      </Toolbar>
    </AppBar>
  );
}
