import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useData } from '../contexts/DataContexst';
import ProductBarChart from './Charts/ProductBarChart';

const CustomCard: React.FC<{
  title: string;
  value: string;
  Chart?: boolean;
}> = ({ title, value, children, Chart = false }) => (
  <Card
    style={{
      maxWidth: Chart ? '90%' : '40%',
      minWidth: Chart ? '90%' : '40%',
      margin: 50,
    }}
    className="center"
  >
    <CardContent>
      <Typography variant="h3" textAlign="center">
        {title}
      </Typography>
      <Typography variant="h6" textAlign="center">
        {value}
      </Typography>
      {children}
    </CardContent>
  </Card>
);

const Base_Mony = 10825;

const Reports = () => {
  const { Orders, Expensess } = useData();
  const ExpensessTotal = Expensess.reduce((p, c) => p + c.Price, 0);
  const DonePayment = Orders.reduce(
    (perv, curr) => perv + (curr.payment ? curr.total : 0),
    0
  );
  const Profits = DonePayment - ExpensessTotal;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <CustomCard title="الارباح" value={Profits.toString()} />
      <CustomCard title="مجموع المصاريف" value={ExpensessTotal.toString()} />
      <CustomCard title="راس المال" value={Base_Mony.toString()} />
      <CustomCard
        title="الموجود بالحساب"
        value={(DonePayment + Base_Mony - ExpensessTotal).toString()}
      />
      <CustomCard
        title="مبلغ الطلبات"
        value={Orders.reduce((perv, curr) => perv + curr.total, 0).toString()}
      />
      <CustomCard title="المبالغ المدفوعة" value={DonePayment.toString()} />
      <CustomCard title="عدد الطلبات" value={Orders.length.toString()} />
      <CustomCard
        title="المبالغ المتبقية"
        value={Orders.reduce(
          (p, c) => p + (c.payment ? 0 : c.total),
          0
        ).toString()}
      />
      <CustomCard Chart title="المنتجات المباعه" value="">
        <ProductBarChart />
      </CustomCard>
    </div>
  );
};

export default Reports;
