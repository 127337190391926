import {
  IconButton, Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { deleteDoc, doc, getFirestore } from '@firebase/firestore';
import { Table } from 'react-bootstrap';
import NewType from './NewExpensess';
import EditType from './EditExpensess';
import { useData } from '../../contexts/DataContexst';
import app from '../../firebase';

const Index = () => {
  const { Expensess } = useData();

  return (
    <div>
      <NewType />
      <Table aria-label="simple table" responsive bordered>
        <thead>
          <tr>
            <th className="text-center">الاسم</th>
            <th className="text-center">السعر</th>
            <th className="text-center">تعديل</th>
            <th className="text-center">حذف</th>
          </tr>
        </thead>
        <tbody>
          {Expensess.map((data) => (
            <tr
              key={data.id}
            >
              <td className="text-center"><Typography variant="h3">{data.Name}</Typography></td>
              <td className="text-center"><Typography variant="h3">{data.Price}</Typography></td>
              <td className="text-center">
                <IconButton size="large">
                  <EditType type={data} />
                </IconButton>
              </td>
              <td className="text-center">
                <IconButton
                  size="large"
                  onClick={async () => {
                    const db = getFirestore(app);

                    await deleteDoc(doc(db, 'expensess', data.id));
                  }}
                >
                  <Delete />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

    </div>
  );
};

export default Index;
