import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { useData } from '../../contexts/DataContexst';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const ProductBarChart = () => {
  const {Orders} = useData();
  const [labels, setLabels] = useState(['']);
  const [data, setData] = useState<number[]>([]);

  type productCountType = {
    [key: string]: number;
  }

  useEffect(() => {
    const productsCount:productCountType = {};
    Orders.forEach((order) => {
      order.Products.forEach((product) => {
        if (!productsCount[product.product.Name]) {
          productsCount[product.product.Name] = product.count;
        } else {
          productsCount[product.product.Name] += product.count;
        }
      });
    });
    setLabels(Object.keys(productsCount));
    setData(Object.values(productsCount));
  }, [Orders]);

  return (
    <div className="App">
      <Bar
        height={300}
        width={400}
        data={{
          labels,
          datasets: [
            {
              label: '',
              data,
              backgroundColor: '#6e0447',
            },
          ],
        }}
        plugins={[ChartDataLabels]}
        options={
          {
            font: {
              weight: 'bold',
              size: 20,
            },
            scales:{
              x:{
                ticks:{
                  font:{
                    size:20,
                  }
                }
              },
            },
            maintainAspectRatio:false,
            plugins: {
              datalabels: {
                display: true,
                anchor: 'end',
                align: 'top',
                formatter: Math.round,
                font: {
                  weight: 'bold',
                  size: 18,
                },
              },
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
          }
        }

      />
    </div>
  );
};

export default ProductBarChart;
