import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function Login() {
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  async function handleSubmit(e:any) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login();
      history('/');
    } catch (e) {
      setError(`Failed to log in${e}`);
    }

    setLoading(false);
  }

  return (
    <div>
      <button disabled={loading} onClick={handleSubmit}>Sign in</button>
      <h1>{error}</h1>
    </div>
  );
}
