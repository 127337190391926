import {
  IconButton, Typography,
} from '@mui/material';
import React from 'react';
import { Table } from 'react-bootstrap';
import NewType from './NewType';
import EditType from './EditType';
import { useData } from '../../contexts/DataContexst';

const Index = () => {
  const { Types } = useData();

  return (
    <div>
      <NewType />

      <Table aria-label="simple table" responsive bordered>
        <thead>
          <tr>
            <th className="text-center">ألاسم</th>
            <th className="text-center">السعر</th>
            <th className="text-center">تعديل</th>
          </tr>
        </thead>
        <tbody>
          {Types.map((data) => (
            <tr
              key={data.id}
            >
              <td className="text-center"><Typography variant="h3">{data.Name}</Typography></td>
              <td className="text-center"><Typography variant="h3">{data.Price}</Typography></td>
              <td className="text-center">
                <IconButton size="large">
                  <EditType type={data} />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

    </div>
  );
};

export default Index;
