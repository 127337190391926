/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions,
  IconButton, FormControl,
  InputLabel, MenuItem, Select, Typography,
  List, ListItem, ListItemText, useMediaQuery, Checkbox, FormControlLabel, FormGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { FieldArray, Formik, useFormikContext } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, MobileDatePicker, DesktopDatePicker } from '@mui/lab';
import * as yup from 'yup';
import { OrderType } from '../../types';
import app from '../../firebase';
import { useData } from '../../contexts/DataContexst';

const AutoUpdateTotal = () => {
  // we use it to update total
  // becuase setfieldvalue is not updating the value instantaneously so we use useEffect
  const { values, setFieldValue } = useFormikContext<OrderType>();
  const sumTotal = (vals:OrderType) => {
    let tot = 0;
    vals.Products.forEach((v) => {
      tot += v.count * v.product.Price;
    });
    return tot;
  };

  useEffect(() => {
    setFieldValue('total', sumTotal(values));
  }, [values.Products]);

  return null;
};

const EditOrder = ({ Order }:{Order:OrderType}) => {
  const [open, setOpen] = useState(false);
  const [SelectedTypes,
    setSelectedTypes] = useState<string[]>(Order.Products.map((v) => v.product.id));
  const [currentSelectedType, setcurrentSelectedType] = useState<string>('');
  const OrderSchema = yup.object().shape({
    CustomerName: yup.string().required(),
    Products: yup.array().min(1).of(yup.object().shape({
      count: yup.number().required().min(1),
      product: yup.object().shape({
        Name: yup.string().required(),
        Price: yup.number().required(),
      }),
    })),
    notes: yup.string(),
    place: yup.string().required(),
    total: yup.number(),
    delivry: yup.boolean().required(),
    payment: yup.boolean().required(),
  });

  const { Types } = useData();

  useEffect(() => {
    if (!(Order.date instanceof Date)) {
      Order.date = (Order.date as any).toDate();
    }
  }, []);

  const isMobile = useMediaQuery('(max-width: 760px)');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <IconButton onClick={handleClickOpen} size="large">
        <EditIcon fontSize="large" />
      </IconButton>
      <Formik
        initialValues={Order}
        onSubmit={async (values, { setSubmitting }) => {
          await setDoc(doc(getFirestore(app), 'orders/', values.id), values);

          setSubmitting(false);
          setSelectedTypes([]);
          handleClose();
        }}
        validationSchema={OrderSchema}
      >
        {({
          values, isSubmitting, handleChange, handleBlur, setFieldValue, handleSubmit, errors, touched,
        }) => (

          <Dialog fullScreen open={open} onClose={handleClose}>
            <DialogTitle style={{ display: 'flex' }}>
              <Typography variant="h5">تعديل طلب</Typography>
              <Typography variant="h5" flex={1} align="right" width="100%">{values.total}</Typography>
            </DialogTitle>
            <DialogContent>
              <div>
                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  id="CustomerName"
                  label="ألاسم"
                  variant="standard"
                  name="CustomerName"
                  value={values.CustomerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  type="text"
                  error={!!errors.CustomerName && touched.CustomerName}
                  helperText={touched.CustomerName && errors.CustomerName}
                />

                <FieldArray
                  name="Products"
                  render={(Helpers) => (
                    <div>
                      <div style={{ width: '100%', marginTop: 10, display: 'flex' }}>
                        <FormControl style={{ width: '50%' }}>
                          <InputLabel id="demo-simple-select-label">النوع</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentSelectedType}
                            renderValue={(v) => Types.find((p) => p.id === v)?.Name}
                            label="النوع"
                            onChange={(e) => {
                              setcurrentSelectedType(e.target.value);
                            }}
                            disabled={isSubmitting}
                          >
                            {Types.filter((v) => !SelectedTypes.includes(v.id)).map((typ) => (
                              <MenuItem value={typ.id} key={typ.id}>{typ.Name}</MenuItem>
                            ))}
                          </Select>

                        </FormControl>
                        <Button
                          style={{ width: '50%', alignItems: 'center' }}
                          variant="contained"
                          type="submit"
                          onClick={() => {
                            if (currentSelectedType.trim() === '') return;
                            setSelectedTypes((typ) => [...typ, currentSelectedType]);
                            // setFieldValue(`Products[${values.Products.length}]`,{
                            //   count:1,
                            //   product:Types.find((p)=>p.id===currentSelectedType)?.data()
                            // })
                            Helpers.push({
                              count: 1,
                              product: Types.find((p) => p.id === currentSelectedType)!,
                            });
                            setcurrentSelectedType('');
                          }}
                          disabled={isSubmitting}
                        >
                          إضافة النوع
                        </Button>
                      </div>
                      <List>

                        {values.Products.map((val, idx) => (
                          <ListItem
                            key={idx}
                            secondaryAction={(
                              <IconButton
                                disabled={isSubmitting}
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  Helpers.remove(idx);
                                  setSelectedTypes((tp) => tp.filter(
                                    (v) => !_.isEqual(Types.find((x) => x.id === v), val.product),
                                  ));
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                    )}
                          >
                            <ListItemText>{val.product.Name}</ListItemText>
                            <TextField
                              disabled={isSubmitting}
                              id={`Products[${idx}].product.count`}
                              label="العدد"
                              variant="standard"
                              name={`${Helpers.name}[${idx}].count`}
                              value={values.Products[idx].count.toString()}
                              onChange={(e) => {
                                const num = parseInt(e.target.value, 10);

                                if (isNaN(num)) setFieldValue(`${Helpers.name}[${idx}].count`, 0);
                                else setFieldValue(`${Helpers.name}[${idx}].count`, parseInt(e.target.value, 10));
                              }}
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              type="number"
                              onBlur={handleBlur}
                              error={(() => {
                                if (errors.Products) if (errors.Products[idx]) return true;

                                return false;
                              })()}
                              helperText={(() => {
                                if (errors.Products) if (errors.Products[idx]) return ((errors.Products[idx])as any).count;

                                return '';
                              })()}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  )}
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {
                    isMobile
                      ? (
                        <MobileDatePicker
                          disabled={isSubmitting}
                          value={values.date}
                          onChange={(val) => {
                            setFieldValue('date', val);
                          }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="dd/MM/yyyy"
                        />
                      )
                      : (
                        <DesktopDatePicker
                          disabled={isSubmitting}
                          value={values.date}
                          onChange={(val) => {
                            setFieldValue('date', val);
                          }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="dd/MM/yyyy"
                        />
                      )
                }
                </LocalizationProvider>

                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  id="place"
                  label="المكان"
                  variant="standard"
                  name="place"
                  value={values.place}
                  onChange={handleChange}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  type="text"
                  onBlur={handleBlur}
                  error={!!errors.place && touched.place}
                  helperText={touched.place && errors.place}
                />

                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  id="notes"
                  label="ملاحظات"
                  variant="standard"
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  type="text"
                  onBlur={handleBlur}
                  error={!!errors.notes && touched.notes}
                  helperText={touched.notes && errors.notes}
                />
                <FormGroup>
                  <FormControlLabel disabled={isSubmitting} control={<Checkbox name="delivry" onBlur={handleBlur} onChange={handleChange} checked={values.delivry} />} label="التوصيل" />
                  <FormControlLabel disabled={isSubmitting} control={<Checkbox name="payment" onBlur={handleBlur} onChange={handleChange} checked={values.payment} />} label="السداد" />
                </FormGroup>

                <AutoUpdateTotal />

              </div>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting}
                onClick={handleClose}
              >
                الغاء
              </Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                اضافة
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>

    </div>
  );
};

export default EditOrder;
