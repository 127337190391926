import React, { useContext, useState, useEffect } from 'react';
import {
  signInWithPopup, GoogleAuthProvider, User, UserCredential,
} from 'firebase/auth';
import { auth } from '../firebase';

const AuthContext = React.createContext<{currentUser: User | null,
  login:() => Promise<UserCredential>,
  logout: () => Promise<void>}>(null as any);

export function useAuth() {
  return useContext(AuthContext);
}

export var AuthProvider = ({ children }:any) => {
  const provider = new GoogleAuthProvider();

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  function login() {
    return signInWithPopup(auth, provider);
  }

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
