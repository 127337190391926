// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBL3JJtC8jAarHqWl7R_KhxDT3FOIuc08U',
  authDomain: 'almr-b8d74.firebaseapp.com',
  projectId: 'almr-b8d74',
  storageBucket: 'almr-b8d74.appspot.com',
  messagingSenderId: '586610530873',
  appId: '1:586610530873:web:1cbf869c83c38367b60be8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
