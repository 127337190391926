/* eslint-disable react-hooks/exhaustive-deps */
import {
  collection, DocumentData, getFirestore, onSnapshot, query,
} from '@firebase/firestore';
import React, { useContext, useState, useEffect } from 'react';
import app from '../firebase';
import { ExpensessType, OrderType, productType } from '../types';

type DataTypes = {
  Types:productType[],
  Orders:OrderType[],
  Expensess:ExpensessType[],
}

const DataContext = React.createContext<DataTypes>(null as any);

export function useData() {
  return useContext(DataContext);
}

export var DataProvider = ({ children }:any) => {
  const [Types, setTypes] = useState<productType[]>([]);
  const [Orders, setOrders] = useState<OrderType[]>([]);
  const [Expensess, setExpensess] = useState<ExpensessType[]>([]);

  const db = getFirestore(app);

  useEffect(() => {
    const Typeslistener = onSnapshot(query(collection(db, 'types')), (snapshot) => {
      const types: DocumentData[] = [];
      snapshot.forEach((doc) => {
        types.push(doc.data());
      });
      setTypes(types as any);
    });
    return () => Typeslistener();
  }, []);

  useEffect(() => {
    const Orderslistener = onSnapshot(query(collection(db, 'orders')), (snapshot) => {
      const orders: DocumentData[] = [];
      snapshot.forEach((doc) => {
        orders.push(doc.data());
      });
      setOrders(orders as any);
    });
    return () => Orderslistener();
  }, []);

  useEffect(() => {
    const Expensesslistener = onSnapshot(query(collection(db, 'expensess')), (snapshot) => {
      const orders: DocumentData[] = [];
      snapshot.forEach((doc) => {
        orders.push(doc.data());
      });
      setExpensess(orders as any);
    });
    return () => Expensesslistener();
  }, [db]);

  const value:DataTypes = {
    Orders,
    Types,
    Expensess,
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};
